import {
  RESPONSE_VIEW_TABS,
  ResponseViewEvents,
  SwitchTabEvent,
} from "@msicie/public-types";

// eslint-disable-next-line import/prefer-default-export
export const SHOW_GENERIC_ERROR_MESSAGES_FEATURE_FLAG =
  "b_showGenericErrorMessages";

export const MONITOR_SWITCH_TAB_DATA_EXPLORER: SwitchTabEvent = {
  type: ResponseViewEvents.SWITCH_TAB,
  payload: RESPONSE_VIEW_TABS.DATA_EXPLORER,
};

export const PDF_STYLES = `
html {
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

body {
  margin: 24px !important;
}

button[data-cr="dms-button"] {
  display: none !important;
}

.dms-cobalt .main-panel-list-div {
  overflow: hidden;
}

@media print {
  @page {
    size: A4 portrait;
    margin: 24px;
  }

  body {
    margin: 0 !important;
    overflow-wrap: break-word;
  }
  
  .operation-geninfo-card__wrapper {
    display: block !important;
  }
  
  .dms-image__photo--noev {
    display: flex !important;
    max-width: 90% !important;
    max-height: none !important;
    overflow: visible !important;
  }
  
  .dms-type-hint {
    white-space: pre-wrap;
  }
  
   .checkout-base-table {
   max-height: none !important;
   overflow: visible !important;
 }
}
`;
