// Generic
import React, { useEffect, useRef, useState } from "react";
// Components
import { Image } from "dms-lib";
// Models
import {
  FieldPhotoStatus,
  InfoFieldPhoto,
  isMediaAvailable,
  isMediaWaiting,
  MediaFileStatus,
  MediaInfo,
  useI18n,
} from "compass-commons";
import GetMediaFileError from "../../../../../errors/GetMediaFileError";
// Services
import MediaFileManagerService from "../../../../../services/MediaFileManagerService";
// Helpers
import { buildCaption } from "../../../../../helpers/captionHelper";
import StateService from "../../../../../services/StateService";
import { useStateContext } from "../../../../../contexts/StateContext";
import { printError } from "../../../../../utils/Util";

interface InfoFieldPhotoProps {
  infoFieldPhoto: InfoFieldPhoto;
}

const InfoFieldPhotoComponent = ({
  infoFieldPhoto,
}: InfoFieldPhotoProps): JSX.Element => {
  const { t } = useI18n();
  const [imageData, setImageData] = useState<InfoFieldPhoto>(infoFieldPhoto);
  const [imageWithQuality, setImageWithQuality] = useState<string | null>(null);
  const stateService: StateService = useStateContext();
  const { imagesData } = stateService;

  // Create a media file Polling for each image
  // Purpose: Check if image is still being proccessed
  // Only trigger setTimeout if image is still being prepared
  const imagePollingTimeout = useRef(null);

  const checkIsRequested = (fieldPhotoStatus: FieldPhotoStatus) =>
    fieldPhotoStatus !== FieldPhotoStatus.NOT_REQUESTED;

  const mediaPollingCondition = (mediaFile: MediaInfo) =>
    !mediaFile || isMediaWaiting(mediaFile.mediaFileStatus);

  const canRequestContent = (mediaFileStatus: MediaFileStatus) =>
    isMediaAvailable(mediaFileStatus);

  const startPolling = () =>
    checkIsRequested(imageData?.fieldPhotoStatus) &&
    mediaPollingCondition(imageData?.mediaFile);

  useEffect(() => {
    const currentImages = imagesData.getValue();
    const storedImage = currentImages.find(
      (image) => image.fileId === imageData.fileId
    );
    if (storedImage) {
      currentImages.splice(currentImages.indexOf(storedImage), 1, imageData);
    } else {
      currentImages.push(imageData);
    }
    imagesData.next(currentImages);
  }, [imageData]);

  const getMedia = async (): Promise<MediaInfo> => {
    try {
      const result = await MediaFileManagerService.getMediaById(
        imageData.fileId
      );
      return result;
    } catch (error) {
      clearTimeout(imagePollingTimeout?.current);
      throw new GetMediaFileError(error);
    }
  };

  const convertBlobToBase64 = (blob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const loadImage = async (fileId: string = null): Promise<any> => {
    try {
      const content = await MediaFileManagerService.getMediaContentById(fileId);
      if (content) {
        setImageWithQuality(await convertBlobToBase64(content));
      }
    } catch (error) {
      printError(error);
    }
  };

  const mediaPolling = async () => {
    let mediaInfo: MediaInfo;
    let pollingError = false;
    try {
      mediaInfo = await getMedia();

      if (mediaPollingCondition(mediaInfo)) {
        imagePollingTimeout.current = setTimeout(mediaPolling, 1000);
        return;
      }
    } catch (error) {
      pollingError = true; // Important flag to alert finally that an error occurred
    } finally {
      if (
        canRequestContent(mediaInfo?.mediaFileStatus) &&
        imageWithQuality === null
      ) {
        await loadImage(imageData.fileId);
      }
      // Important to keep track of what was the previous state.
      // When polling is possible that mediaFile is still null therefore we need to keep polling.
      setImageData((prevState: InfoFieldPhoto) => ({
        ...prevState,
        mediaFile: {
          ...(mediaInfo || prevState?.mediaFile),
          thumbnail: {
            ...(mediaInfo?.thumbnail || prevState?.mediaFile?.thumbnail),
          },
          mediaFileStatus:
            (pollingError && MediaFileStatus.UPLOAD_FAILED) ||
            mediaInfo?.mediaFileStatus ||
            prevState?.mediaFile?.mediaFileStatus,
        },
      }));
    }
  };

  // Conditions to handle different use cases
  const hasError =
    !imageData.fileId ||
    !isMediaAvailable(imageData.mediaFile?.mediaFileStatus);

  const isRequested = checkIsRequested(imageData.fieldPhotoStatus);

  const isPolling = startPolling();

  useEffect(() => {
    if (startPolling()) mediaPolling();

    return () => {
      clearTimeout(imagePollingTimeout?.current);
    };
  }, []);

  return (
    <Image
      id={imageData.fileId}
      src={
        imageWithQuality ||
        `data:${imageData.mediaFile?.thumbnail?.mediaType};base64,${imageData.mediaFile?.thumbnail?.content}`
      }
      alt="addition info"
      isRequested={isRequested}
      canRequest={false}
      placeholderText={t("snapshots.notRequested")}
      hasError={hasError}
      isLoading={isPolling}
      iconOnly
      caption={buildCaption(
        {
          fileId: infoFieldPhoto.fileId,
          resourceMappingName: imageData.resourceMapping?.name,
          mediaTimestamp:
            imageData.mediaFile?.mediaTimestamp || imageData.desiredTimestamp,
        },
        "\n"
      )}
    />
  );
};

export default InfoFieldPhotoComponent;
