import React from "react";
import "./incidentStepContent.module.css";
import { IncidentStep, incidentStepType } from "compass-commons";
import {
  getActivationsGroupContent,
  getAssignedContent,
  getClearContent,
  getCommentContent,
  getGenericContent,
  getManualContent,
  getPostAlarmContent,
  getResourceInteractionContent,
  getWithOperatorIcon,
} from "./IncidentStepDataContent";
import ResourceInteractionStep from "../../../models/resource/ResourceInteractionStep";
import GenericInfoCardTask from "./genericInfoCardTask/GenericInfoCardTask";

const IncidentStepContent = (props: {
  incidentStep: IncidentStep;
  isSystemStep: boolean;
  incidentId: string;
  withOperatorIcon?: boolean;
}): JSX.Element => {
  const { incidentStep, isSystemStep, withOperatorIcon, incidentId } = props;

  const getIncidentDataElements = () => {
    let content: JSX.Element = <></>;

    if (incidentStep?.type) {
      switch (incidentStep.type.toLowerCase()) {
        case incidentStepType.SNAP:
        case incidentStepType.AUTO:
        case incidentStepType.AUDIO:
        case incidentStepType.CALL:
        case incidentStepType.CONTACT:
        case incidentStepType.ATTACH:
        case incidentStepType.VIDEO: {
          content = getGenericContent(incidentStep);
          break;
        }
        case incidentStepType.MANUAL_TASK: {
          content = getManualContent(incidentId, incidentStep);
          break;
        }
        case incidentStepType.POST: {
          content = getPostAlarmContent(incidentStep);
          break;
        }
        case incidentStepType.COMMENT: {
          content = getCommentContent(incidentStep);
          break;
        }
        case incidentStepType.ASSIGNED: {
          content = getAssignedContent(incidentStep);
          break;
        }
        case incidentStepType.CLR: {
          content = getClearContent(incidentStep);
          break;
        }
        case incidentStepType.RESOURCE_INTERACTION: {
          const resourceInteraction: ResourceInteractionStep =
            incidentStep as ResourceInteractionStep;
          content = getResourceInteractionContent(resourceInteraction);
          break;
        }
        case incidentStepType.GENERIC_INFO: {
          const genericInfo = incidentStep.genericInfoList;
          if (genericInfo) {
            content = (
              <GenericInfoCardTask
                taskId={incidentStep.id}
                incidentStep={incidentStep}
              />
            );
          }

          break;
        }
        case incidentStepType.ACTIVATIONS_GROUP: {
          content = getActivationsGroupContent(incidentStep);
          break;
        }
        default: {
          content = (
            <span data-cr={incidentStep.type}>This step is not mapped</span>
          );
        }
      }
    }

    return incidentStep.user != null &&
      !isSystemStep &&
      incidentStep.type !== incidentStepType.CLR &&
      withOperatorIcon
      ? getWithOperatorIcon(content, incidentStep.user)
      : content;
  };

  return <div data-cr="incident-step-content">{getIncidentDataElements()}</div>;
};

IncidentStepContent.defaultProps = {
  withOperatorIcon: true,
};

export default IncidentStepContent;
