// General
import React, { useEffect, useState } from "react";
import {
  LocalizationNS,
  ReportCart,
  ReportCartStateEnum,
  useFeatureFlag,
  useI18n,
} from "compass-commons";
import { BehaviorSubject } from "rxjs";
// Components
import { Alert, Button } from "dms-lib";
import MainPanel from "./components/mainPanel/MainPanel";
// Services
import ReportCartService from "./services/ReportCartService";
// Utils
import { createReportCart, normalizeCart } from "./utils/Util";
import { SHOW_GENERIC_ERROR_MESSAGES_FEATURE_FLAG } from "./utils/Constants";
// Errors
import MoreThanOneActiveReportCart from "./errors/MoreThanOneActiveReportCart";
import ActiveReportCartNotExistsError from "./errors/ActiveReportCartNotExistsError";
// Helpers
import useWindowDimensions from "./helpers/useWindowDimensions";
import { ReportGenerateSource } from "./models/ReportGenerateSource";
import StateService from "./services/StateService";
import LeftSideButtons from "./components/mainPanel/buttons/LeftSideButtons";
import RightSideButtons from "./components/mainPanel/buttons/RightSideButtons";

interface AppLayoutProps {
  currentReportCart: BehaviorSubject<ReportCart>;
  generatingPdf: BehaviorSubject<ReportGenerateSource>;
  progressPdf: BehaviorSubject<number>;
}

const stateService: StateService = new StateService();

const AppLayout = ({
  currentReportCart,
  generatingPdf,
  progressPdf,
}: AppLayoutProps): JSX.Element => {
  const { t: translate } = useI18n();
  const [reportCart, setReportCart] = useState(null);
  const { alertSubject } = stateService;
  const dimensions = useWindowDimensions();
  const appStyle = {
    height: dimensions.height - 90,
  };

  useEffect(() => {
    if (reportCart == null) {
      ReportCartService.getCarts(ReportCartStateEnum.ACTIVE)
        .then((res) => {
          if (res && res.length > 0) {
            if (res.length === 1) {
              const normalizedCart = normalizeCart(res[0]);
              currentReportCart.next(normalizedCart);
              setReportCart(normalizedCart);
            } else {
              throw new MoreThanOneActiveReportCart();
            }
          } else {
            createReportCart()
              .then((cart) => {
                if (cart) {
                  setReportCart(cart);
                  currentReportCart.next(cart);
                }
              })
              .catch(() => {
                setReportCart(null);
                currentReportCart.next(null);
              });
          }
        })
        .catch((error) => {
          throw new ActiveReportCartNotExistsError(error);
        });
    }

    const reportCartSubscription = currentReportCart.subscribe((val) => {
      setReportCart(val);
    });

    return function cleanup() {
      reportCartSubscription.unsubscribe();
    };
  }, [currentReportCart, reportCart]);

  const { enabled: showErrorMessagesFeatureFlag } = useFeatureFlag(
    appConfig,
    SHOW_GENERIC_ERROR_MESSAGES_FEATURE_FLAG
  );
  const handleRefresh = () => {
    window.location.reload();
  };
  const refreshAction = (
    <Button size="small" color="primary" variant="text" onClick={handleRefresh}>
      Refresh
    </Button>
  );

  useEffect(() => {
    const handleReject = () => {
      alertSubject.next({
        title: translate("genericErrorTitle", { ns: LocalizationNS.SHARED }),
        description: translate("genericErrorSubtitle", {
          ns: LocalizationNS.SHARED,
        }),
        action: refreshAction,
      });
    };
    if (showErrorMessagesFeatureFlag) {
      window.addEventListener("unhandledrejection", handleReject);
    }
    return () => {
      window.removeEventListener("unhandledrejection", handleReject);
    };
  }, [showErrorMessagesFeatureFlag]);

  return (
    <div
      className="report-checkout-main-div"
      style={appStyle}
      data-cr="report-checkout-main"
    >
      <div className="report-checkout-div">
        {reportCart && (
          <>
            <LeftSideButtons currentReportCart={currentReportCart} />
            <MainPanel reportCartSubject={currentReportCart} />
            <RightSideButtons
              generatingPdf={generatingPdf}
              progressPdf={progressPdf}
              alertSubject={alertSubject}
              reportCart={reportCart}
            />
          </>
        )}
      </div>
      <Alert alertNotificationSubject={alertSubject} />
    </div>
  );
};

export default AppLayout;
