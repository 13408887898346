// Generic
import React, { useEffect, useMemo, useRef, useState } from "react";
// Styles
import "./genericInfoCardTask.module.css";
// Components
import {
  GenericInfoCardType,
  IncidentStep,
  InfoField,
  InfoFieldPhoto,
  InfoFieldText,
  InfoFieldTypes,
  InfoFieldUrl,
  useI18n,
} from "compass-commons";
import InfoFieldPhotoComponent from "./infoFieldsData/InfoFieldPhotoComponent";
import InfoFieldTextComponent from "./infoFieldsData/InfoFieldTextComponent";
import InfoFieldUrlComponent from "./infoFieldsData/InfoFieldUrlComponent";
import { getSpan } from "../IncidentStepDataContent";

export const InfoFieldToGenericInfoMapper = Object.freeze({
  TEXT: "DATA",
  PHOTO: "PHOTO",
  URL: "DATA",
});

interface GenericInfoCardTaskProps<T extends InfoField> {
  taskId: string;
  incidentStep: IncidentStep;
}

const GenericInfoCardTask = <T extends InfoField>({
  taskId,
  incidentStep,
}: GenericInfoCardTaskProps<T>): JSX.Element => {
  const { t } = useI18n();
  const imageWidthContainerRef = useRef(null);

  const [mediaData, setMediaData] = useState<{
    [key in GenericInfoCardType]: JSX.Element[];
  }>({
    [GenericInfoCardType.PHOTO]: [],
    [GenericInfoCardType.DATA]: [],
  });

  const hasOnlyPhotos = (): boolean => {
    return incidentStep.genericInfoList?.every(
      ({ infoFieldType }) => infoFieldType === InfoFieldTypes.PHOTO
    );
  };

  // Mapping from InfoField data to Component
  const InfoFieldToComponent = {
    [InfoFieldTypes.PHOTO]: (infoPhoto: InfoField) => (
      <InfoFieldPhotoComponent infoFieldPhoto={infoPhoto as InfoFieldPhoto} />
    ),
    [InfoFieldTypes.TEXT]: (infoText: InfoField) => (
      <InfoFieldTextComponent infoFieldText={infoText as InfoFieldText} />
    ),
    [InfoFieldTypes.URL]: (infoUrl: InfoField) => (
      <InfoFieldUrlComponent infoFieldUrl={infoUrl as InfoFieldUrl} />
    ),
  };

  const getPhotoData = useMemo(() => mediaData.PHOTO, [mediaData.PHOTO]);
  const getInfoData = useMemo(() => mediaData.DATA, [mediaData.DATA]);
  const hasOnlyPhotosData = hasOnlyPhotos();

  const prepareMediaData = () => {
    const tempMediaData = {
      [GenericInfoCardType.PHOTO]: [],
      [GenericInfoCardType.DATA]: [],
    };

    incidentStep?.genericInfoList.forEach((infof: InfoField) => {
      tempMediaData[InfoFieldToGenericInfoMapper[infof.infoFieldType]].push(
        InfoFieldToComponent[infof.infoFieldType](infof)
      );
    });

    setMediaData(tempMediaData);
  };

  useEffect(() => {
    prepareMediaData();
  }, []);

  return (
    <div
      className="operation-geninfo-card__wrapper"
      id={`${incidentStep?.id}#${taskId}`}
    >
      {incidentStep.title && (
        <div className="timeline-small-bold-text">
          {`${incidentStep.title}`}
        </div>
      )}
      {hasOnlyPhotosData && (
        <div className="timeline-small-text">
          {t("snapshots.nrOfImages", {
            count: incidentStep.genericInfoList.length,
          })}
        </div>
      )}
      <div className="operation-geninfo-card__container">
        {(getInfoData.length && (
          <div className="operation-geninfo-card__info">{...getInfoData}</div>
        )) ||
          null}
        {(getPhotoData.length && (
          <div
            ref={imageWidthContainerRef}
            className="operation-geninfo-card__media operation-geninfo-card__images--list"
          >
            {...getPhotoData}
          </div>
        )) ||
          null}
      </div>
    </div>
  );
};

export default GenericInfoCardTask;
