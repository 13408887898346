// General
import React from "react";
import { Incident, useI18n } from "compass-commons";
// Components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import IncidentStepContent from "./IncidentStepContent";
import IncidentHeaderContent from "./IncidentHeaderContent";
// Styles
import "./compassDetailsTable.module.css";
// Utils
import { mapStringDateToTimezone } from "../../../utils/Util";
import IncidentIconWrapper from "../incidentIconWrapper/IncidentIconWrapper";

interface CompassDetailsTableProps {
  incident: Incident;
}

const CompassDetailsTable = (props: CompassDetailsTableProps): JSX.Element => {
  const { incident } = props;

  const { t: translate } = useI18n();

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      border: 0,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
      boxShadow: 0,
    },
  }));

  const StyledTableRow = styled(TableRow)(() => ({
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const getSteps = () => {
    return incident?.systemSteps && incident?.operatorSteps
      ? incident.systemSteps.concat(incident.operatorSteps)
      : [];
  };

  return (
    <>
      {incident && (
        <div className="table-wrapper">
          <TableContainer>
            <Table
              sx={{ minWidth: 700 }}
              aria-label="customized table"
              id="details-table"
            >
              <TableBody className="dms-table-body-details-view">
                <StyledTableRow>
                  <TableCell>
                    <div className="compass-details-table-header-icon-div">
                      {incident?.icon ? (
                        <IncidentIconWrapper
                          triggerType={incident.triggerType}
                          incident={incident}
                        />
                      ) : (
                        <b>{translate("detailsTable.incident")}</b>
                      )}
                    </div>
                  </TableCell>
                  <StyledTableCell align="left" />
                  <StyledTableCell align="left">
                    <IncidentHeaderContent incident={incident} />
                  </StyledTableCell>
                  <StyledTableCell align="left" style={{ width: 20 }} />
                </StyledTableRow>
                {getSteps().map((step) => (
                  <StyledTableRow key={`${step.id}__${step.creationTimestamp}`}>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      style={{ width: 10, verticalAlign: "top" }}
                    >
                      {mapStringDateToTimezone(step.creationTimestamp)}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      style={{
                        width: 10,
                      }}
                    >
                      {step.user?.nameInitials &&
                        step.user?.nameInitials.length === 2 && (
                          <b style={{ color: "var(--primary)" }}>
                            {step.user.nameInitials?.toUpperCase()}
                          </b>
                        )}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <IncidentStepContent
                        incidentStep={step}
                        incidentId={incident.id}
                        isSystemStep
                        withOperatorIcon={false}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="left" />
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </>
  );
};

export default CompassDetailsTable;
