import React from "react";
import { Incident, IncidentTriggerType, useI18n } from "compass-commons";
import NewReleasesRoundedIcon from "@mui/icons-material/NewReleasesRounded";
import { IncidentIcon } from "dms-lib";

interface IncidentIconWrapperProps {
  triggerType: IncidentTriggerType;
  incident: Incident;
}

const IncidentIconWrapper = ({
  triggerType,
  incident,
}: IncidentIconWrapperProps): JSX.Element => {
  const { t: translate } = useI18n();

  if (triggerType !== "ON-DEMAND") {
    return (
      <IncidentIcon
        src={incident.icon}
        priority={incident.priority}
        size="extra-large"
      />
    );
  }

  return (
    <IncidentIcon
      src={incident.icon}
      priority={incident.priority}
      size="extra-large"
      badgeIcon={<NewReleasesRoundedIcon />}
      badgeProps={{
        tooltip: {
          title: translate("detailsTable.triggerTypeTooltip.onDemand"),
        },
        dataCr: "incident-icon-wrapper-badge",
      }}
    />
  );
};

export default IncidentIconWrapper;
